<template>
  <div>
    <v-card class="rounded-lg flex flex-column" flat @click="consumeService(service)" :ripple="false">

      <v-card-text>

        <div class="ribbon ribbon-top-right" v-if="service.promotions_user.length > 0">
          <span class="font-weight-medium text-capitalize">Promotion</span>
        </div>

         <v-avatar size="50" :color="service.background_color" class="rounded-lg">
           <v-img :src="getImageLink(service.image)"
                  contain></v-img>
         </v-avatar>

      </v-card-text>

    </v-card>
  </div>
</template>
  
<script>
export default {
  components: {},
  props: ["service"],
  methods: {
    consumeService(service) {
      this.$emit("consumeService", service);
    },
  },
};
</script>

<style scoped>
.ribbon {
  width: 120px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 3px 0;
  background-color: #FFAB02;
  color: #fff;
  text-align: center;
  z-index: 2;
}

/* top right*/
.ribbon-top-right {
  top: 0px;
  right: 0px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 22px;
}
.ribbon-top-right::after {
  bottom: 22px;
  right: 0;
}
.ribbon-top-right span {
  left: -27px;
  top: 22px;
  transform: rotate(45deg);
}
</style>